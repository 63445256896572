import { CommonCustomizableData } from 'components/forms/Model'
import { SingleCardCustomizableData } from '../../../components/common/single-card/Model'
import { ChangePasswordFormCustomizableData } from '../../../components/forms/changePasswordForm/Model'
import { ForgotPasswordFormCustomizableData } from '../../../components/forms/forgotPasswordForm/Model'
import { LoginFormCustomizableData } from '../../../components/forms/loginForm/Model'
import { NewPasswordFormCustomizableData } from '../../../components/forms/newPasswordForm/Model'

export class AuthAPIConfig {
    constructor(public CredentialsHeader: string = '', public BaseUrl: string = '', public requestTimeout = 1000) {}
}

export class DocumentAPIConfig extends AuthAPIConfig {
    constructor(
        BaseUrl = '',
        public ApiKey: string = '',
        public MaxFileSize: number = 100000,
        public AllowedFileExtensions: string[] = ['.jpg', '.jpeg', '.png', '.tiff', '.pdf']
    ) {
        super(BaseUrl)
    }
}

export class EnvConfigModel {
    constructor(public AuthAPI: AuthAPIConfig = new AuthAPIConfig(), public DocumentAPI: DocumentAPIConfig = new DocumentAPIConfig()) {}
}

export class AppConfigModel {
    static readonly DefaultRedirectToAppTime = 1000
    static readonly DefaultMaxRedirectsToApp = 5

    constructor(public redirectToAppTime: number = AppConfigModel.DefaultRedirectToAppTime, public maxRedirectsToApp: number = AppConfigModel.DefaultMaxRedirectsToApp) {}
}

export class StylesheetsModel {
    constructor(public Available: string[] = [], public Active: number = -1) {}
}

export class ContentModel {
    constructor(
        public SignIn: LoginFormCustomizableData = new LoginFormCustomizableData(),
        public SignUp: SingleCardCustomizableData = new SingleCardCustomizableData(),
        public ForgotPassword: ForgotPasswordFormCustomizableData = new ForgotPasswordFormCustomizableData(),
        public ChangePassword: ChangePasswordFormCustomizableData = new ChangePasswordFormCustomizableData(),
        public NewPassword: NewPasswordFormCustomizableData = new NewPasswordFormCustomizableData(),
        public ResetPassword: SingleCardCustomizableData = new SingleCardCustomizableData(),
        public TFARegister: SingleCardCustomizableData = new SingleCardCustomizableData(),
        public TFAVerify: SingleCardCustomizableData = new SingleCardCustomizableData(),
        public TermsOfService: SingleCardCustomizableData = new SingleCardCustomizableData(),
        public Common: CommonCustomizableData = new CommonCustomizableData()
    ) {}
}

export class CommonConfigModel {
    constructor(
        public AppConfig: AppConfigModel = new AppConfigModel(),
        public Stylesheets: StylesheetsModel = new StylesheetsModel(),
        public Content: ContentModel = new ContentModel()
    ) {}
}
