import ScrollView from 'devextreme-react/scroll-view'
import './singleCard.scss'
import { SingleCardProps } from './Model'
import { ReactSVG } from 'react-svg'
import { useConfig } from '../../../contexts'

const SingleCard = (props: SingleCardProps): JSX.Element => {
    const { firmImageBaseUrl } = useConfig()
    const className = 'with-footer single-card '.concat(props.className)
    const logoImg = `${firmImageBaseUrl}/app-logo.svg`
    const hasFooter = props.footerText !== undefined && props.footerText !== ''
    return (
        <ScrollView height={'100%'} width={'100%'} className={className}>
            <div className={'dx-card content'}>
                <div className={'header'}>
                    <ReactSVG src={logoImg} className="app-logo" />
                    <div className={'title'}>{props.title}</div>
                    <div className={'description'}>{props.description}</div>
                </div>

                {props.children}

                {hasFooter ? (
                    <div className={'footer'}>
                        <div className={'information'}>{props.footerText}</div>
                    </div>
                ) : null}
            </div>
        </ScrollView>
    )
}

export default SingleCard
