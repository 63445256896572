import { useRef, useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, PatternRule, CompareRule } from 'devextreme-react/form'
import { useAuth, useConfig } from '../../../contexts'
import notify from 'devextreme/ui/notify'
import ChangePasswordFormProps from './Model'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import '../form.scss'
import { HttpErrorCodes } from '../../../clients/api/auth/Model'
import { ErrorMessageProps } from '../../common/errorMessage/Model'

const errorMessageClear = new ErrorMessageProps()

const ChangePasswordForm = (): JSX.Element => {
    const navigate = useNavigate()
    const { commonConfig } = useConfig()
    const { getUser, changePassword, username, loading } = useAuth()

    const [mfaEnabled, setMfaEnabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState(errorMessageClear)

    const customContent = commonConfig.Content.ChangePassword
    const customContentCommon = commonConfig.Content.Common
    const userLabel = customContent.userInputText
    const props = new ChangePasswordFormProps(username)
    const formData = useRef({ ...props })

    const getUserInfo = useCallback(
        async (usernameParam: string) => {
            try {
                setErrorMessage(errorMessageClear)
                const userInfo = await getUser(usernameParam)
                if (userInfo == null || userInfo.code !== HttpErrorCodes.OK) {
                    setErrorMessage(new ErrorMessageProps(`Change Password Error: ${userInfo?.message}`, ''))
                    return
                }
                setMfaEnabled(userInfo.mfaEnabled)
            } catch (error) {
                setMfaEnabled(false)
            }
        },
        [getUser]
    )

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        if (formData.current.user !== '' && !mfaEnabled) getUserInfo(formData.current.user)
    }, [getUserInfo, formData, mfaEnabled])

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            e.preventDefault()
            try {
                setErrorMessage(errorMessageClear)
                const response = await changePassword(formData.current)
                if (response == null || response.code !== HttpErrorCodes.OK) {
                    setErrorMessage(new ErrorMessageProps(`Change Password Error: ${response?.message}`, ''))
                    return
                }
                notify('Password changed successfully', 'success', 2000)
                navigate('/')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Change Password Form Error' : error.response.data.error.message
                notify(msg, 'error', 2000)
            }
        },
        [changePassword, navigate]
    )

    const onUsernameChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            await getUserInfo(formData.current.user)
        },
        [getUserInfo, formData]
    )

    const usernameEditorOptions = {
        stylingMode: 'outlined',
        label: userLabel,
        labelMode: 'floating',
        mode: 'text',
        onValueChanged: onUsernameChanged,
        elementAttr: {
            'field-id': 'username-code-id',
        },
    }
    const passwordOrigEditorOptions = {
        stylingMode: 'outlined',
        label: 'Password',
        labelMode: 'floating',
        mode: 'password',
        elementAttr: {
            'field-id': 'password-code-id',
        },
    }
    const passwordNewEditorOptions = {
        stylingMode: 'outlined',
        label: 'New Password',
        mode: 'password',
        labelMode: 'floating',
        elementAttr: {
            'field-id': 'password-new-code-id',
        },
    }
    const passwordNewRepeatEditorOptions = {
        stylingMode: 'outlined',
        label: 'Confirm New Password',
        mode: 'password',
        labelMode: 'floating',
        elementAttr: {
            'field-id': 'password-new-confirm-code-id',
        },
    }
    const authCodeEditorOptions = {
        stylingMode: 'outlined',
        placeholder: 'Authentication Code',
        labelMode: 'floating',
        elementAttr: {
            'field-id': 'auth-code-id',
        },
    }
    const submitButtonAttributes = {
        class: 'submit-button',
        elementAttr: {
            'field-id': 'submit-button-code-id',
        },
    }
    return (
        <SingleCard {...customContent}>
            <form className={'forgot-password-form'} onSubmit={onSubmit}>
                <Form formData={formData.current} disabled={loading}>
                    <Item dataField={'user'} editorType={'dxTextBox'} editorOptions={usernameEditorOptions}>
                        <RequiredRule message="Username is required" />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordOrigEditorOptions}>
                        <RequiredRule message="Password is required" />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'passwordNew'} editorType={'dxTextBox'} editorOptions={passwordNewEditorOptions}>
                        <RequiredRule />
                        <PatternRule
                            pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                            message="At least 8 characters, 1 number, 1 uppercase character, 1 of the following !@#$%^&*"
                        />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'passwordNewConfirm'} editorType={'dxTextBox'} editorOptions={passwordNewRepeatEditorOptions}>
                        <RequiredRule message="Password is required" />
                        <CompareRule message={'Passwords do not match'} comparisonType={'=='} comparisonTarget={() => formData.current.passwordNew} />
                        <Label visible={false} />
                    </Item>
                    {mfaEnabled && (
                        <Item dataField={'code'} editorType={'dxTextBox'} editorOptions={authCodeEditorOptions}>
                            <RequiredRule message="Authentication code is required" />
                            <Label visible={false} />
                        </Item>
                    )}
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">{loading === true ? <Loading /> : customContent.submitButtonText}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {errorMessage.message !== '' ? (
                        <Item>
                            <ErrorMessage {...errorMessage} />
                        </Item>
                    ) : null}
                    <Item>
                        <div className={'login-link'}>
                            {customContentCommon.backToLoginPrefix}
                            <Link to={'/'}>{customContentCommon.backToLoginLinkText}</Link>
                        </div>
                    </Item>
                </Form>
            </form>
        </SingleCard>
    )
}

export default ChangePasswordForm
